import styled from "styled-components";

// Used for wrapping a page component
export const Screen = styled.div`
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: 300% 300%;
  background-image: linear-gradient(
        -45deg, 
        rgba(255, 198, 0) 0%, 
        rgba(233, 0, 255) 25%, 
        rgba(88, 0, 255) 51%, 
        rgba(0, 0, 0, 1) 100%
  );  
  // animation: AnimateBG 20s ease infinite;
  // @keyframes AnimateBG { 
  //   0%{background-position:0% 50%}
  //   50%{background-position:100% 50%}
  //   100%{background-position:0% 50%}
  // }
`;

// Used for providing space between components
export const SpacerXSmall = styled.div`
  height: 8px;
  width: 8px;
`;

// Used for providing space between components
export const SpacerSmall = styled.div`
  height: 16px;
  width: 16px;
`;

// Used for providing space between components
export const SpacerMedium = styled.div`
  height: 24px;
  width: 24px;
`;

// Used for providing space between components
export const SpacerLarge = styled.div`
  height: 32px;
  width: 32px;
`;

// Used for providing a wrapper around a component
export const Container = styled.div`
  display: flex;
  flex: ${({ flex }) => (flex ? flex : 0)};
  flex-direction: ${({ fd }) => (fd ? fd : "column")};
  justify-content: ${({ jc }) => (jc ? jc : "flex-start")};
  align-items: ${({ ai }) => (ai ? ai : "flex-start")};
  height: 100%;
  width: 100%;
  background-size: cover;
  background-position: center;
`;

export const TextTitle = styled.p`
  color: white;
  font-size: 22px;
  font-weight: 500;
  line-height: 1.6;
`;

// Used for providing a wrapper around a component
export const StyledPromptInput = styled.input`
padding: 10px;
width: 300px;
margin: 10px;
font-size: 16px;
box-sizing: border-box;
background-color: rgba(0, 0, 0, 0.1);
color: white;
@media (min-width: 500px) {
  width: 400px;
}
@media (min-width: 800px) {
  width: 800px;
}
`;

export const TextSubTitle = styled.p`
  color: white;
  font-size: 18px;
  line-height: 1.6;
`;

export const TextDescription = styled.p`
  color: white;
  font-size: 16px;
  line-height: 1.6;
`;

export const TextDescriptionTerms = styled.p`
  color: white;
  font-size: 12px;
  line-height: 1.6;  
`;

export const TextFeedback = styled.p`
  color: white;
  font-size: 18px;
  font-weight: bold;
  line-height: 1.6;
`;

export const StyledClickable = styled.div`
  :active {
    opacity: 0.6;
  }
`;

export const StyledButton = styled.button`
  /* Adapt the colors based on primary prop */
  background: black;
  color: white;
  cursor: pointer;
  font-size: 1em;
  margin: 1em;
  padding: 0.25em 1em;
  border: 2px solid white;
  border-radius: 3px;
`;
