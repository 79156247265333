import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { connect } from "./redux/blockchain/modal";
import { fetchData, fetchNFTs } from "./redux/data/dataActions";
import Web3 from "web3";
import best_next10 from "./redux/data/best_next10.json";
import traits_rarities from "./redux/data/traits_rarities.json";
import * as s from "./styles/globalStyles";
import styled from "styled-components";
import Loader from "react-loader-spinner";
import DownloadLink from "react-download-link";
import ReactGA from "react-ga4";
import CookieConsent, { getCookieConsentValue, Cookies } from "react-cookie-consent";
import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import OAuth2Login from 'react-simple-oauth2-login';

const truncate = (input, len) =>
  input.length > len ? `${input.substring(0, len)}...` : input;

export const StyledButton = styled.button`
  padding: 10px;
  border: none;
  background-color: rgba(0, 0, 0, 0.3);;
  padding: 10px;
  font-weight: bold;
  color: white;
  width: 200px;
  cursor: pointer;
  box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;

export const StyledMenuButton = styled.button`
  border: none;
  background-color: rgba(0, 0, 0, 0.3);
  padding: 5px;
  font-weight: bold;
  color: white;
  width: 100%;
  cursor: pointer;
  font-size: 16px;
  box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
  @media (min-width: 1600px) {
    width: 200px;
  }
`;

export const StyledRoundButton = styled.button`
  padding: 10px;
  border-radius: 100%;
  border: none;
  background-color: rgba(0, 0, 0, 0.3);;
  padding: 10px;
  font-weight: bold;
  font-size: 15px;
  color: var(--primary-text);
  width: 30px;
  height: 30px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;

export const ResponsiveWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: stretched;
  align-items: stretched;
  width: 100%;
  @media (min-width: 900px) {
    flex-direction: row;
  }
`;

export const ResponsiveWrapperMenu = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: stretched;
  align-items: stretched;
  width: 100%;
  @media (min-width: 900px) {
    flex-direction: row;    
  }
`;

export const ResponsiveWrapperRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex: 1;
  flex-direction: row;
  justify-content: stretched;
  align-items: stretched;
  width: 100%;
  @media (min-width: 900px) {
    flex-direction: row;
  }
`;

export const StyledLogo = styled.img`
  width: 100px;
  @media (min-width: 100px) {
    width: 100px;
  }
  transition: width 0.5s;
  transition: height 0.5s;
`;

export const StyledPartner = styled.img`
  width: 150px;
  transition: width 0.5s;
  transition: height 0.5s;
`;

export const StyledImg = styled.img`
  box-shadow: 0px 5px 11px 2px rgba(0, 0, 0, 0.7);
  border: 4px solid var(--secondary);
  background-color: rgba(0, 0, 0, 0.3);;
  width: 100px;
  @media (min-width: 900px) {
    width: 250px;
  }
  @media (min-width: 1000px) {
    width: 300px;
  }
  transition: width 0.5s;
`;

export const StyledImgNFT = styled.img`
  box-shadow: 0px 5px 11px 2px rgba(0, 0, 0, 0.7);
  border: 4px solid var(--secondary);
  background-color: rgba(0, 0, 0, 0.3);;
  width: 150px;
  @media (min-width: 900px) {
    width: 400px;
  }
  @media (min-width: 1000px) {
    width: 400px;
  }
  transition: width 0.5s;
`;

export const StyledImgDream = styled.img`
  box-shadow: 0px 5px 11px 2px rgba(0, 0, 0, 0.7);
  border: 4px solid var(--secondary);
  background-color: rgba(0, 0, 0, 0.3);;
  width: 100%;
  @media (min-width: 900px) {
    width: 512px;
  }
  @media (min-width: 1000px) {
    width: 512px;
  }
  transition: width 0.5s;
`;

export const StyledLink = styled.a`
  color: var(--secondary);
  text-decoration: none;
`;

export const RagdollerLink = styled.a`
  color: var(--secondary);
  text-decoration: none;
  font-size: 20px;
  text-decoration: underline;
  color:  white
`;

function App() {
  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);
  const data = useSelector((state) => state.data);
  const [dreams, setDreams] = useState({});
  const [dreams_results, setDreamsResults] = useState({});
  const [discord_username, setDiscordUsername] = useState("");
  const [dream_message, setDreamMessage] = useState("")
  const [show_minting, setShowMinting] = useState(false);
  const [show_nfts, setShowNFTs] = useState(false);
  const [show_dreams, setShowDreams] = useState(false);
  const [show_gallery_setup, setGallerySetup] = useState(false);
  const [show_discord_connection, setDiscordConnection] = useState(false);
  const [claimingNft, setClaimingNft] = useState(false);
  const [feedback, setFeedback] = useState(``);
  const [mintAmount, setMintAmount] = useState(1);
  const [errorMsg, setErrorMsg] = useState("");
  const [dreamerrorMsg, setDreamErrorMsg] = useState("");
  const [dream_desabled, setDreamDisabled] = useState(false);
  const [discord_disabled, setDiscordDisabled] = useState(false);
  const [signed_address, setAddress] = useState('');
  const [signed_signature, setSignature] = useState('');
  const [signed_message, setMessage] = useState('');

  const [CONFIG, SET_CONFIG] = useState({
    CONTRACT_ADDRESS: "",
    SCAN_LINK: "",
    NETWORK: {
      NAME: "",
      SYMBOL: "",
      ID: 0,
    },
    NFT_NAME: "",
    SYMBOL: "",
    MAX_SUPPLY: 1,
    WEI_COST: 0,
    DISPLAY_COST: 0,
    GAS_LIMIT: 0,
    MARKETPLACE: "",
    MARKETPLACE_LINK: "",
    SHOW_BACKGROUND: false,
  });

  const send_event = (event, value) => {
    const isConsent = getCookieConsentValue();
    if (isConsent === "true") {
      ReactGA.event({
        category: "user action",
        action: event,
        value: value
      });
    }
  };

  const handleAcceptCookie = () => {
    ReactGA.initialize("G-R6X2DHJ6GW");
    ReactGA.send("pageview");
  };

  const handleDeclineCookie = () => {
    //remove google analytics cookies
    Cookies.remove("_ga");
    Cookies.remove("_gat");
    Cookies.remove("_gid");
  };

  const claimAirdrop = () => {
    let gasLimit = CONFIG.GAS_LIMIT;
    let totalGasLimit = String(gasLimit * mintAmount);
    console.log("Gas limit: ", totalGasLimit);
    setFeedback(`After confirming, the minting of your ${CONFIG.NFT_NAME} starts. It will take about 10 seconds`);
    setClaimingNft(true);

    // Estimate the gas to check if the transaction will fail
    console.log(blockchain.proof)
    console.log(blockchain.account)
    blockchain.smartContract.methods.claimAirdrop(blockchain.proof).estimateGas({
      gasLimit: String(totalGasLimit),
      to: CONFIG.CONTRACT_ADDRESS,
      from: blockchain.account,
    }).then((receipt) => {
      console.log(receipt)
      blockchain.smartContract.methods
        .claimAirdrop(blockchain.proof)
        .send({
          gasLimit: String(totalGasLimit),
          to: CONFIG.CONTRACT_ADDRESS,
          from: blockchain.account,
        })
        .once("error", (err) => {
          console.log(err);
          setFeedback("Sorry, something went wrong! Try again later.");
          setClaimingNft(false);
        })
        .then((receipt) => {
          console.log(receipt);
          setFeedback(
            `WOW, the ${CONFIG.NFT_NAME} is yours!`
          );
          setClaimingNft(false);
          dispatch(fetchData(blockchain.account));
        });
    }).catch((err) => {
      console.log(err);
      let err_split = err.message.split("execution reverted: ")
      let reason = err_split[err_split.length - 1]
      setFeedback("Invalid call to the contract! \n The transaction would revert. \n Reason: \n" + reason);
      setClaimingNft(false);
    })
  };

  const claimNFTs = () => {
    let cost = CONFIG.WEI_COST;
    let gasLimit = CONFIG.GAS_LIMIT;
    let totalCostWei = String(cost * mintAmount);
    let totalGasLimit = String(gasLimit * mintAmount);
    console.log("Cost: ", totalCostWei);
    console.log("Gas limit: ", totalGasLimit);
    setFeedback(`After confirming, the minting of your ${CONFIG.NFT_NAME} starts. It will take about 10 seconds`);
    setClaimingNft(true);

    // Estimate the gas to check if the transaction will fail
    blockchain.smartContract.methods.mint(mintAmount).estimateGas({
      gasLimit: String(totalGasLimit),
      to: CONFIG.CONTRACT_ADDRESS,
      from: blockchain.account,
      value: totalCostWei,
    }).then((receipt) => {
      console.log(receipt)
      blockchain.smartContract.methods
        .mint(mintAmount)
        .send({
          gasLimit: String(totalGasLimit),
          to: CONFIG.CONTRACT_ADDRESS,
          from: blockchain.account,
          value: totalCostWei,
        })
        .once("error", (err) => {
          console.log(err);
          setFeedback("Sorry, something went wrong! Try again later.");
          setClaimingNft(false);
        })
        .then((receipt) => {
          send_event("purchase", CONFIG.DISPLAY_COST * mintAmount);
          console.log(receipt);
          setFeedback(
            `WOW, you have a ${CONFIG.NFT_NAME} is yours!`
          );
          setClaimingNft(false);
          dispatch(fetchData(blockchain.account));
        });
    }).catch((err) => {
      console.log(err);
      let err_split = err.message.split("execution reverted: ")
      let reason = err_split[err_split.length - 1]
      setFeedback("Invalid call to the contract! \n The transaction would revert. \n Reason: \n" + reason);
      setClaimingNft(false);
    })
  };

  const sign_message = () => {
    console.log(Cookies.get("signature"))
    if (typeof Cookies.get("signature") === 'undefined') {
      var timestamp = String(Math.floor(Date.now() / 1000))
      let message = "Signature needed for accessing CronosRagdolls API.\nSigning a message does have a cost.\nSigned with timestamp: " + timestamp;
      let walletAddress = blockchain.account
      let password = '' //not necessary, only used on javascript wallet

      let web3 = new Web3(blockchain.provider);
      web3.eth.extend({
        methods: [
          {
            name: "chainId",
            call: "eth_chainId",
            outputFormatter: web3.utils.hexToNumber
          }
        ]
      });
      web3.eth.personal.sign(
        message,
        walletAddress,
        password,
        function (err, signature) {
          if (err) {
            console.log('Signature Denied');
            console.log(err)
            return;
          }
          if (signature) {
            console.log('Signed!');
            console.log('Address:');
            console.log(walletAddress);
            setAddress(walletAddress)
            Cookies.set("walletAddress", walletAddress)
            console.log('Message:');
            console.log(message);
            setMessage(message)
            Cookies.set("message", message)
            console.log('Signature:');
            console.log(signature);
            setSignature(signature)
            Cookies.set("signature", signature)
            return;
          }
        }
      ).then((receipt) => {
        return receipt
      }).catch((err) => { console.log(err); return null })
    } else {
      let walletAddress = Cookies.get("walletAddress")
      let message = Cookies.get("message")
      let signature = Cookies.get("signature")
      console.log('Signed!');
      console.log('Address:');
      console.log(walletAddress);
      setAddress(walletAddress)
      console.log('Message:');
      console.log(message);
      setMessage(message)
      console.log('Signature:');
      console.log(signature);
      setSignature(signature)
    }
  }

  const onSuccessDiscord = response => console.log(response);
  const onFailureDiscord = response => console.error(response);

  const getDiscordSignature = () => {
    let walletAddress = Cookies.get("walletAddress")
    let message = Cookies.get("message")
    let signature = Cookies.get("signature")
    return JSON.stringify({
      wallet: walletAddress,
      message: message,
      signature: signature,
    })
  }

  const postDream = (cat_id, token) => {
    setDreamDisabled(true)
    let prompt = dreams[cat_id]
    let token_id = token.name.replace('CRONOS RAGDOLL #', '')
    let walletAddress = Cookies.get("walletAddress")
    let message = Cookies.get("message")
    let signature = Cookies.get("signature")
    if (prompt) {
      if (prompt.trim() !== "") {
        fetch('https://galleryapi.cronosragdolls.com/api/dream_request/' + token_id, {
          method: 'POST',
          body: JSON.stringify({
            wallet: walletAddress,
            message: message,
            signature: signature,
            prompt: prompt
          }),
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        }).then((response) => response.json()).then((responseJson) => {
          console.log(responseJson)
          let notification = "Result of sending " + token.name + " to bed: " + responseJson.status
          NotificationManager.success(notification);
          setDreamMessage(notification)
          setDreamDisabled(false)
        }).catch((reason) => {
          console.log(reason)
          NotificationManager.error(reason.detail);
          setDreamMessage(reason.detail)
          setDreamDisabled(false)
        })
      } else {
        NotificationManager.warning('Give something to dream');
        setDreamMessage("Give something to dream")
        setDreamDisabled(false)
      }
    } else {
      NotificationManager.warning('Give something to dream');
      setDreamMessage("Give something to dream")
      setDreamDisabled(false)
    }
  }
  const getDiscordUsername = () => {
    let walletAddress = Cookies.get("walletAddress")
    let message = Cookies.get("message")
    let signature = Cookies.get("signature")
    fetch('https://galleryapi.cronosragdolls.com/api/get_discord_user/', {
      method: 'POST',
      body: JSON.stringify({
        wallet: walletAddress,
        message: message,
        signature: signature,
      }),
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    }).then((response) => response.json()).then((responseJson) => {
      setDiscordUsername(responseJson['username'])
    }).catch((reason) => {
      console.log(reason)
    })
  }

  const getDreams = () => {
    let walletAddress = Cookies.get("walletAddress")
    let message = Cookies.get("message")
    let signature = Cookies.get("signature")
    fetch('https://galleryapi.cronosragdolls.com/api/get_dreams/', {
      method: 'POST',
      body: JSON.stringify({
        wallet: walletAddress,
        message: message,
        signature: signature,
      }),
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    }).then((response) => response.json()).then((responseJson) => {
      setDreamsResults(responseJson)
    }).catch((reason) => {
      console.log(reason)
    })
  }

  const getImageURL = (dream) => {
    let url = "https://galleryapi.cronosragdolls.com/api/get_dream_image/"
    return url + Cookies.get("walletAddress") + "/" + dream.token_id + "/" + dream.image
  }

  const decrementMintAmount = () => {
    let newMintAmount = mintAmount - 1;
    if (newMintAmount < 1) {
      newMintAmount = 1;
    }
    setMintAmount(newMintAmount);
  };

  const incrementMintAmount = () => {
    let newMintAmount = mintAmount + 1;
    if (newMintAmount > 10) {
      newMintAmount = 10;
    }
    setMintAmount(newMintAmount);
  };

  const getData = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      dispatch(fetchData(blockchain.account));
    }
  };

  const getNFTs = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      dispatch(fetchNFTs(blockchain.account));
    }
  };

  const getConfig = async () => {
    const configResponse = await fetch("/config/config.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const config = await configResponse.json();
    SET_CONFIG(config);
  };

  useEffect(() => {
    getConfig();
    const isConsent = getCookieConsentValue();
    if (isConsent === "true") {
      handleAcceptCookie();
    }
  }, []);

  useEffect(() => {
    getData();
  }, [blockchain.account]);

  return (
    <s.Screen>
      <CookieConsent
        enableDeclineButton
        onAccept={handleAcceptCookie}
        onDecline={handleDeclineCookie}
        buttonText="Ok, purrfect"
        declineButtonText="No cookies for the cats"
        style={{
          background: "darkblue",
        }}
        buttonStyle={{
          background: "lightgreen",
          color: "black",
        }}
        declineButtonStyle={{
          background: "lightgray",
          color: "black",
        }}
      >
        We use cookies to improve our Website and your user experience
      </CookieConsent>
      <s.Container flex ai={"start"} jc={"center"} fd={"row"}>
        <s.TextSubTitle>
          {data.loading ? "Loading" : null}
        </s.TextSubTitle>
        <s.SpacerSmall />
      </s.Container>
      <ResponsiveWrapperMenu flex={1} style={{ padding: 24, maxWidth: 1600, minHeight: 100 }}>
        <s.Container flex ai={"start"} jc={"center"} fd={"row"}>
          <s.SpacerSmall />
          <StyledLogo alt={"logo"} src={"/config/images/transparent_logo.png"} />
          <s.SpacerSmall />
        </s.Container>
        {blockchain.account != "" &
          blockchain.smartContract != null ?
          <>
            <s.SpacerSmall />
            <s.Container flex ai={"start"} jc={"start"} fd={"row"}>
              <StyledMenuButton
                onClick={(e) => {
                  getData();
                  setShowMinting(true);
                  setShowDreams(false)
                  setShowNFTs(false)
                  setGallerySetup(false)
                  setDiscordConnection(false)
                }}>
                Mint Ragdolls
              </StyledMenuButton>
            </s.Container>
            <s.Container flex ai={"start"} jc={"start"} fd={"row"}>
              <StyledMenuButton
                onClick={(e) => {
                  setShowMinting(false);
                  setShowNFTs(false)
                  setGallerySetup(false)
                  getNFTs();
                  setShowDreams(true)
                  setDiscordConnection(false)
                }}>
                Send to Dream
              </StyledMenuButton>
            </s.Container>
            <s.Container flex ai={"start"} jc={"start"} fd={"row"}>
              <StyledMenuButton
                onClick={(e) => {
                  setShowMinting(false);
                  setShowDreams(false)
                  setShowNFTs(false)
                  setGallerySetup(true)
                  setDiscordConnection(false)
                  if (signed_signature !== '') {
                    getDreams();
                  }
                }}>
                See Dreams
              </StyledMenuButton>
            </s.Container>
            <s.Container flex ai={"start"} jc={"start"} fd={"row"}>
              <StyledMenuButton
                onClick={(e) => {
                  setShowMinting(false);
                  setShowDreams(false);
                  setGallerySetup(false)
                  setShowNFTs(true);
                  getNFTs();
                  setDiscordConnection(false)
                }}>
                My Ragdolls
              </StyledMenuButton>
            </s.Container>
            <s.Container flex ai={"start"} jc={"start"} fd={"row"}>
              <StyledMenuButton
                onClick={(e) => {
                  setShowMinting(false);
                  setShowDreams(false);
                  setGallerySetup(false)
                  setShowNFTs(false);
                  getNFTs();
                  getDiscordUsername();
                  setDiscordConnection(true)
                }}>
                Link Discord
              </StyledMenuButton>
            </s.Container>
          </> :
          <>
            <s.Container flex ai={"start"} jc={"start"} fd={"row"}>
              <s.TextTitle>
                Welcome to the dApp of Cronos Ragdolls! Connect to continue.
              </s.TextTitle>
            </s.Container>
            <s.Container flex ai={"start"} jc={"start"} fd={"row"}>
              <StyledMenuButton
                onClick={(e) => {
                  send_event("login", 0);
                  e.preventDefault();
                  dispatch(connect())
                  getData();
                }}>
                Connect to Cronos
              </StyledMenuButton>
            </s.Container>
          </>
        }
      </ResponsiveWrapperMenu>
      {
        blockchain.errorMsg !== "" ?
          <ResponsiveWrapper flex={1} style={{ padding: 24, minWidth: 300, maxWidth: 1600, minHeight: 100 }}>
            <s.Container
              flex={1}
              jc={"center"}
              ai={"center"}
            >
              <s.SpacerSmall />
              <s.TextTitle
                style={{
                  textAlign: "center",
                  color: "white",
                }}
              >
                Try connecting again!
              </s.TextTitle>
              <s.SpacerSmall />
              <s.TextSubTitle
                style={{
                  textAlign: "center",
                  color: "white",
                }}
              >
                {blockchain.errorMsg}
              </s.TextSubTitle>
            </s.Container>
          </ResponsiveWrapper>
          : null
      }
      {
        data.loading ?
          <ResponsiveWrapper flex={1} style={{ padding: 24, maxWidth: 1600, minHeight: 100 }}>
            <s.Container
              flex={1}
              jc={"center"}
              ai={"center"}
              style={{ padding: 24, backgroundColor: "rgba(0, 0, 0, 0.1)", minHeight: 700, maxHeight: 700, maxWidth: 1600 }}
            >
              <s.TextDescription
                style={{
                  textAlign: "center",
                  color: "var(--accent-text)",
                }}
              >
                {"Loading. Wait ^._.^= ∫ (purr, purr)"}
              </s.TextDescription>
            </s.Container>
          </ResponsiveWrapper>
          :
          show_minting ?
            <s.Container
              flex={1}
              jc={"center"}
              ai={"center"}
              style={{ padding: 24, backgroundColor: "rgba(0, 0, 0, 0.1)", minHeight: 700, maxWidth: 1600 }}
            >
              <ResponsiveWrapper flex={1} style={{ padding: 24 }}>
                <s.Container
                  flex={1}
                  jc={"center"}
                  ai={"center"}
                  style={{
                    padding: 24,
                    border: "4px solid var(--secondary)",
                    boxShadow: "0px 5px 11px 2px rgba(0,0,0,0.7)"
                  }}
                >
                  {Number(data.totalSupply) >= CONFIG.MAX_SUPPLY ? (
                    <>
                      <s.TextTitle
                        style={{ textAlign: "center", color: "var(--accent-text)" }}
                      >
                        The sale has ended.
                      </s.TextTitle>
                      <s.TextDescription
                        style={{ textAlign: "center", color: "var(--accent-text)" }}
                      >
                        You can still find {CONFIG.NFT_NAME} on
                      </s.TextDescription>
                      <s.SpacerSmall />
                      <StyledLink target={"_blank"} href={CONFIG.MARKETPLACE_LINK}>
                        {CONFIG.MARKETPLACE}
                      </StyledLink>
                    </>
                  ) : (
                    <>
                      <s.SpacerSmall />
                      <s.Container flex={1} jc={"center"} ai={"center"}>
                        <StyledImg
                          alt={"example"}
                          src={"/config/images/example.gif"}
                          style={{ transform: "scaleX(-1)" }}
                        />
                      </s.Container>
                      <s.SpacerSmall />
                      <s.TextTitle>
                        {"You can still mint some! Only " + data.totalSupply + " of 10000 have been minted"}
                      </s.TextTitle>
                      <s.SpacerSmall />
                      <s.TextTitle>
                        Here are some useful links:
                      </s.TextTitle>
                      <s.SpacerSmall />
                      <s.Container ai={"center"} jc={"center"} fd={"row"}>
                        <RagdollerLink target={"_blank"} href="https://www.cronosragdolls.com/">
                          Official Website
                        </RagdollerLink>
                      </s.Container>
                      <s.SpacerSmall />
                      <s.Container ai={"center"} jc={"center"} fd={"row"}>
                        <RagdollerLink target={"_blank"} href="https://discord.com/invite/sa8UGMJhUZ">
                          Discord Server
                        </RagdollerLink>
                      </s.Container>
                      <s.SpacerSmall />
                      <s.Container ai={"center"} jc={"center"} fd={"row"}>
                        <RagdollerLink target={"_blank"} href="https://cronoscan.com/address/0x21e68422011a330befdf45b0e45072c947316c95#code">
                          Smart Contract
                        </RagdollerLink>
                      </s.Container>
                      <s.SpacerSmall />
                      <s.TextTitle>
                        Each {CONFIG.SYMBOL} NFT costs {CONFIG.DISPLAY_COST}{" "}
                        {CONFIG.NETWORK.SYMBOL} (Excluding gas fees)
                      </s.TextTitle>
                      <s.SpacerXSmall />
                      <s.SpacerSmall />
                      <s.SpacerSmall />
                      {blockchain.account === "" ||
                        blockchain.smartContract === null ? (
                        <s.TextSubTitle>Connect the wallet</s.TextSubTitle>
                      ) : (
                        <>
                          {data.loading ?
                            <>
                              <s.TextDescription
                                style={{
                                  textAlign: "center",
                                  color: "var(--accent-text)",
                                }}
                              >
                                Checking that things will go smoothly ^._.^= ∫ (purr, purr)
                              </s.TextDescription>
                            </>
                            :
                            <>
                              <s.TextDescription
                                style={{
                                  textAlign: "center",
                                  color: "var(--accent-text)",
                                }}
                              >
                                {feedback}
                              </s.TextDescription>
                              <s.SpacerMedium />
                              {/* <s.TextDescription
                          style={{
                            textAlign: "center",
                            color: "var(--accent-text)",
                          }}
                        >
                          ⭐ Best upcoming NFT* in next 100 mints: Rank {best_next10[String(data.totalSupply)]}
                        </s.TextDescription>
                        <s.SpacerMedium /> */}
                              {/* & false disables the airdrop claim */}

                              <s.Container ai={"center"} jc={"center"} fd={"row"}>
                                <StyledRoundButton
                                  style={{ lineHeight: 0.4 }}
                                  disabled={claimingNft ? 1 : 0 | data.paused}
                                  onClick={(e) => {
                                    e.preventDefault();
                                    decrementMintAmount();
                                  }}
                                >
                                  -
                                </StyledRoundButton>
                                <s.SpacerMedium />
                                <s.TextDescription
                                  style={{
                                    textAlign: "center",
                                    color: "var(--accent-text)",
                                  }}
                                >
                                  {data.paused ? "The contract is paused" : mintAmount}
                                </s.TextDescription>
                                <s.SpacerMedium />
                                <StyledRoundButton
                                  disabled={claimingNft ? 1 : 0 | data.paused}
                                  onClick={(e) => {
                                    e.preventDefault();
                                    incrementMintAmount();
                                  }}
                                >
                                  +
                                </StyledRoundButton>
                                <s.Container ai={"center"} jc={"center"} fd={"row"}>
                                  <StyledButton
                                    disabled={claimingNft ? 1 : 0 | data.paused}
                                    // disabled={claimingNft ? 1 : 0}
                                    onClick={(e) => {
                                      send_event("add_to_cart", 0);
                                      e.preventDefault();
                                      claimNFTs();
                                      getData();
                                    }}
                                  >
                                    {claimingNft ? "CONFIRM" : data.paused ? "CAN'T MINT" : "BUY"}
                                    {/* {claimingNft ? "PLEASE CONFIRM" : "BUY"} */}
                                  </StyledButton>
                                </s.Container>
                              </s.Container>
                            </>}
                        </>
                      )
                      }
                    </>
                  )}
                  <s.SpacerMedium />
                  <StyledLink target={"_blank"} href="https://www.cronosragdolls.com/terms-of-use">
                    By owning one Cronos Ragdoll NFT you accept our terms of use.
                  </StyledLink>
                  <s.SpacerMedium />
                </s.Container>
              </ResponsiveWrapper>
            </s.Container > :
            <></>
      }
      {
        show_nfts & !data.loading ?
          <s.Container
            flex={1}
            ai={"center"}
            style={{ padding: 24, backgroundColor: "rgba(0, 0, 0, 0.1)", minHeight: 700, maxWidth: 1600 }}
          >
            <s.SpacerLarge />

            <s.Container flex={1} jc={"left"} ai={"center"}>
              <s.TextDescription
                style={{
                  fontSize: 28,
                  textAlign: "center",
                  color: "var(--primary-text)",
                }}
              >
                {!data.error ?
                  <>
                    {data.user_tokens.length === 0 ? "You don't have RAG" : "You have the following Cronos Ragdolls NFTs:"}
                  </> :
                  <>
                    {data.error ? "Error loading NFTs: " + data.errorMsg : null}
                  </>
                }
              </s.TextDescription>
            </s.Container>
            <s.SpacerLarge />
            <ResponsiveWrapperRow flex={1} style={{ padding: 24 }} ai={"center"}>
              {
                !data.user_tokens ? "" :
                  data.user_tokens.map((e, i) =>
                    <s.Container key={i} flex={1} jc={"center"} ai={"center"}>
                      <StyledImgNFT alt={"example"} src={e.image} />
                      <s.SpacerSmall />
                      <a href={e.image} target="_blank" style={{
                        color: "var(--primary-text)",
                      }}>View full image</a>
                      <s.SpacerSmall />
                      <s.Container flex={1} jc={"left"} ai={"center"}>
                        <s.TextDescription
                          style={{
                            fontSize: 24,
                            textAlign: "left",
                            color: "var(--primary-text)",
                          }}>
                          {e.name}
                        </s.TextDescription>
                      </s.Container>
                      <s.Container flex={1} jc={"left"} ai={"center"}>
                        <s.TextDescription
                          style={{
                            textAlign: "left",
                            color: "var(--primary-text)",
                          }}>
                          {e.description}
                        </s.TextDescription>
                      </s.Container>
                      <s.Container flex={1} jc={"left"} ai={"center"}>
                        <s.TextDescription
                          style={{
                            fontSize: 24,
                            textAlign: "left",
                            color: "var(--primary-text)",
                          }}>
                          Rank #{traits_rarities[e.name.split("#")[1]]["ranking"]}
                        </s.TextDescription>
                      </s.Container>
                      <s.Container flex={1} jc={"left"} ai={"center"}>
                        {e.attributes.map((f, j) =>
                          <s.TextDescription
                            style={{
                              textAlign: "left",
                              color: "var(--primary-text)",
                            }}>
                            {f.trait_type}: {f.value} ({traits_rarities[e.name.split("#")[1]][f.trait_type.toLowerCase()]}%)
                          </s.TextDescription>
                        )}
                      </s.Container>
                      <s.SpacerMedium />
                    </s.Container>
                  )
              }
            </ResponsiveWrapperRow>
          </s.Container> : <></>
      }
      {
        show_dreams & !data.loading ?
          <s.Container
            flex={1}
            ai={"center"}
            style={{ padding: 24, backgroundColor: "rgba(0, 0, 0, 0.1)", minHeight: 700, maxWidth: 1600 }}
          >
            <s.SpacerLarge />
            {signed_signature === '' ?
              <>
                <s.Container flex={1} jc={"left"} ai={"center"}>
                  <s.TextSubTitle>
                    We need to validate in our server that you are the owner of wallet {blockchain.account}.
                  </s.TextSubTitle>
                  <s.SpacerXSmall />
                  <s.TextSubTitle>
                    Sign the following message to authenticate in our server.
                  </s.TextSubTitle>
                  <s.SpacerLarge />
                  <StyledButton
                    onClick={(e) => {
                      send_event("sign_message", 0);
                      e.preventDefault();
                      sign_message();
                    }}
                  >
                    Authenticate API
                  </StyledButton>
                </s.Container>
                <s.SpacerLarge />
                <s.Container flex={1} jc={"left"} ai={"left"}>
                  <s.TextDescriptionTerms>
                    Important Note: We rely on an AI model called Stable Difussion. By using this feature, you agree not to use the Model or Derivatives of the Model:
                  </s.TextDescriptionTerms>
                  <s.TextDescriptionTerms>
                    - In any way that violates any applicable national, federal, state, local or international law or regulation;
                  </s.TextDescriptionTerms>
                  <s.TextDescriptionTerms>
                    - For the purpose of exploiting, harming or attempting to exploit or harm minors in any way;
                  </s.TextDescriptionTerms>
                  <s.TextDescriptionTerms>
                    - To generate or disseminate verifiably false information and/or content with the purpose of harming others;
                  </s.TextDescriptionTerms>
                  <s.TextDescriptionTerms>
                    - To generate or disseminate personal identifiable information that can be used to harm an individual;
                  </s.TextDescriptionTerms>
                  <s.TextDescriptionTerms>
                    - To defame, disparage or otherwise harass others;
                  </s.TextDescriptionTerms>
                  <s.TextDescriptionTerms>
                    - For fully automated decision making that adversely impacts an individual’s legal rights or otherwise creates or modifies a binding, enforceable obligation;
                  </s.TextDescriptionTerms>
                  <s.TextDescriptionTerms>
                    - For any use intended to or which has the effect of discriminating against or harming individuals or groups based on online or offline social behavior or known or predicted personal or personality characteristics;
                  </s.TextDescriptionTerms>
                  <s.TextDescriptionTerms>
                    - To exploit any of the vulnerabilities of a specific group of persons based on their age, social, physical or mental characteristics, in order to materially distort the behavior of a person pertaining to that group in a manner that causes or is likely to cause that person or another person physical or psychological harm;
                  </s.TextDescriptionTerms>
                  <s.TextDescriptionTerms>
                    - For any use intended to or which has the effect of discriminating against individuals or groups based on legally protected characteristics or categories;
                  </s.TextDescriptionTerms>
                  <s.TextDescriptionTerms>
                    - To provide medical advice and medical results interpretation;
                  </s.TextDescriptionTerms>
                  <s.TextDescriptionTerms>
                    - To generate or disseminate information for the purpose to be used for administration of justice, law enforcement, immigration or asylum processes, such as predicting an individual will commit fraud/crime commitment (e.g. by text profiling, drawing causal relationships between assertions made in documents, indiscriminate and arbitrarily-targeted use).
                  </s.TextDescriptionTerms>
                </s.Container>
              </>
              :
              <>
                {!data.error ?
                  <>
                    <ResponsiveWrapperRow flex={1} style={{ padding: 24 }} ai={"center"}>
                      <s.Container flex={1} jc={"left"} ai={"center"}>
                        <s.TextSubTitle>
                          API Authenticated! Let's have some fun.
                        </s.TextSubTitle>
                        <s.SpacerXSmall />
                        <s.TextDescription>
                          You own {data.user_tokens.length} RAGs. You can put each cat to sleep! Just give them an idea on what to dream.
                        </s.TextDescription>
                        <s.SpacerXSmall />
                        <s.TextDescription>
                          To change the mode, +portrait, +landscape, or +square.
                        </s.TextDescription>
                        <s.SpacerXSmall />
                        <s.TextDescription>
                          For example, try with typing the following
                        </s.TextDescription>
                        <s.TextDescription>
                          cat, portrait, finely detailed armor, intricate design, silver, silk, cinematic lighting, 4k +portrait
                        </s.TextDescription>
                        <s.SpacerLarge />
                        <s.TextDescription>
                          For inspiration, visit these links:
                        </s.TextDescription>
                        <s.SpacerXSmall />
                        <StyledLink href="https://strikingloo.github.io/stable-diffusion-vs-dalle-2" target={"_blank"}>Prompt examples</StyledLink>
                        <s.SpacerXSmall />
                        <StyledLink href="https://promptomania.com/stable-diffusion-prompt-builder/" target={"_blank"}>Prompt builder</StyledLink>
                        {/* <s.TextDescription>
                      To control how much the dream follows the text add +g:creative, +g:normal, or +g:strict.
                    </s.TextDescription>
                    <s.TextDescription>
                      To fine tune the quality add a number betwee: +q:50, and +q:99. OGs can add up to +q:150.
                    <`/s.TextDescription> */}
                        <s.SpacerLarge />
                        <s.TextSubTitle>
                          {dream_message}
                        </s.TextSubTitle>
                      </s.Container>
                    </ResponsiveWrapperRow>
                    <ResponsiveWrapperRow flex={1} style={{ padding: 24 }} ai={"center"}>
                      {
                        data.user_tokens.map((e, i) =>
                          <s.Container key={i} flex={1} jc={"center"} ai={"center"}>
                            <s.SpacerSmall />
                            <s.Container flex={1} jc={"left"} ai={"center"}>
                              <s.StyledPromptInput rows="2" id={"prompt_" + i} onChange={(e) =>
                                setDreams((prev) => {
                                  return { ...prev, [i]: e.target.value };
                                })
                              } type="text" name="user" placeholder={"Dream for RAG " + e.name.replace('CRONOS RAGDOLL ', '') + ": Describe a dream you want the cat to have. This will be used to generate an image."} />
                            </s.Container>
                            <s.Container flex={1} jc={"left"} ai={"center"}>
                              <s.StyledButton disabled={dream_desabled} onClick={(ev) => { postDream(i, e) }}>Go to bed RAG {e.name.replace('CRONOS RAGDOLL ', '')}</s.StyledButton>
                            </s.Container>
                            <s.SpacerSmall />
                          </s.Container>
                        )
                      }
                    </ResponsiveWrapperRow>
                  </>
                  :
                  <ResponsiveWrapperRow flex={1} style={{ padding: 24 }} ai={"center"}>
                    <s.Container flex={1} jc={"left"} ai={"center"}>
                      <s.TextSubTitle>
                        There was an error getting the NFTs. Click on Cat Dreams again.
                      </s.TextSubTitle>
                    </s.Container>
                  </ResponsiveWrapperRow>
                }
              </>
            }
            <s.SpacerLarge />
            <ResponsiveWrapperRow flex={1} style={{ padding: 24 }} ai={"center"}>
              {
                !data.user_tokens ?
                  <s.TextDescription>
                    You need a Cronos Ragdoll to use this section.
                  </s.TextDescription>
                  :
                  <></>
              }
            </ResponsiveWrapperRow>
          </s.Container> : <></>
      }
      {
        show_gallery_setup & !data.loading ?
          <s.Container
            flex={1}
            ai={"center"}
            style={{ padding: 24, backgroundColor: "rgba(0, 0, 0, 0.1)", minHeight: 700, maxWidth: 1600 }}
          >
            <s.SpacerLarge />
            {signed_signature === '' ?
              <s.Container flex={1} jc={"left"} ai={"center"}>
                <s.TextSubTitle>
                  We need to validate in our server that you are the owner of wallet {blockchain.account}.
                </s.TextSubTitle>
                <s.SpacerXSmall />
                <s.TextSubTitle>
                  Sign the following message to authenticate in our server.
                </s.TextSubTitle>
                <s.SpacerLarge />
                <StyledButton
                  onClick={(e) => {
                    send_event("sign_message", 0);
                    e.preventDefault();
                    sign_message();
                    getDreams();
                  }}
                >
                  Authenticate API
                </StyledButton>
              </s.Container>
              :
              <>
                {!data.error && dreams_results.length > 0 ?
                  <>
                    <ResponsiveWrapperRow flex={1} style={{ padding: 24 }} ai={"center"}>
                      <s.Container flex={1} jc={"left"} ai={"center"}>
                        <s.TextSubTitle>
                          Check the dreams your Ragdolls had:
                        </s.TextSubTitle>
                      </s.Container>
                    </ResponsiveWrapperRow>
                    {
                      dreams_results.map((e, i) =>
                        <ResponsiveWrapperRow flex={1} style={{ padding: 24 }} ai={"center"}>
                          <s.Container key={i} flex={1} jc={"center"} ai={"center"}>
                            <s.SpacerSmall />
                            <s.TextSubTitle>
                              Cronos Ragdolls #{e.token_id} had the dream of: {e.prompt}. Which resulted in the following image:
                            </s.TextSubTitle>
                            <s.SpacerSmall />
                            {e.image != undefined ?
                              < StyledImgDream alt={"example"} src={getImageURL(e)} />
                              :
                              <s.TextSubTitle>
                                Still dreaming...
                              </s.TextSubTitle>
                            }
                          </s.Container>
                        </ResponsiveWrapperRow>
                      )
                    }
                  </>
                  :
                  <ResponsiveWrapperRow flex={1} style={{ padding: 24 }} ai={"center"}>
                    <s.Container flex={1} jc={"left"} ai={"center"}>
                      <s.TextSubTitle>
                        Your ragdolls have not dreamed anything yet... or... it's still loading.
                      </s.TextSubTitle>
                    </s.Container>
                  </ResponsiveWrapperRow>
                }
              </>
            }
            <s.SpacerLarge />
            <ResponsiveWrapperRow flex={1} style={{ padding: 24 }} ai={"center"}>
              {
                !data.user_tokens ?
                  <s.TextDescription>
                    You need a Cronos Ragdoll to use this section.
                  </s.TextDescription>
                  :
                  <></>
              }
            </ResponsiveWrapperRow>
          </s.Container> : <></>
      }
      {
        show_discord_connection & !data.loading ?
          <s.Container
            flex={1}
            ai={"center"}
            style={{ padding: 24, backgroundColor: "rgba(0, 0, 0, 0.1)", minHeight: 700, maxWidth: 1600 }}
          >
            <s.SpacerLarge />
            {signed_signature === '' ?
              <s.Container flex={1} jc={"left"} ai={"center"}>
                <s.TextSubTitle>
                  We need to validate in our server that you are the owner of wallet {blockchain.account}.
                </s.TextSubTitle>
                <s.SpacerXSmall />
                <s.TextSubTitle>
                  Sign the following message to authenticate in our server.
                </s.TextSubTitle>
                <s.SpacerLarge />
                <StyledButton
                  onClick={(e) => {
                    send_event("sign_message", 0);
                    e.preventDefault();
                    sign_message();
                    getDreams();
                  }}
                >
                  Authenticate API
                </StyledButton>
              </s.Container>
              :
              <>

                {discord_username === '' ?
                  <ResponsiveWrapperRow flex={1} style={{ padding: 24 }} ai={"center"}>
                    <s.Container flex={1} jc={"left"} ai={"center"}>
                      <s.TextSubTitle>
                        Connecting your wallet to your Discord user allows you to get access to special roles. After connecting to discord click on the validate Discord Username button.
                      </s.TextSubTitle>
                    </s.Container>
                    <s.Container flex={1} jc={"left"} ai={"center"}>
                      <OAuth2Login
                        clientId="1063855473406791722"
                        authorizationUrl="https://galleryapi.cronosragdolls.com/api/login"
                        redirectUri="https://galleryapi.cronosragdolls.com/api/callback"
                        onSuccess={onSuccessDiscord}
                        onFailure={onFailureDiscord}
                        state={getDiscordSignature()}
                        isCrossOrigin={true}
                        className='blue-button'
                        responseType='token'
                        buttonText="Connect to Discord"
                      />
                    </s.Container>
                    <s.Container flex={1} jc={"left"} ai={"center"}>
                      <StyledButton
                        onClick={getDiscordUsername}
                      >
                        Validate Discord Username
                      </StyledButton>
                    </s.Container>
                  </ResponsiveWrapperRow>
                  :
                  <ResponsiveWrapperRow flex={1} style={{ padding: 24 }} ai={"center"}>
                    <s.Container flex={1} jc={"left"} ai={"center"}>
                      <s.TextSubTitle>
                        Great! Your Discord username is:
                      </s.TextSubTitle>
                      <s.TextTitle>{discord_username}</s.TextTitle>
                    </s.Container>
                    <s.Container flex={1} jc={"left"} ai={"center"}>
                      <OAuth2Login
                        clientId="1063855473406791722"
                        authorizationUrl="https://galleryapi.cronosragdolls.com/api/login"
                        redirectUri="https://galleryapi.cronosragdolls.com/api/callback"
                        onSuccess={onSuccessDiscord}
                        onFailure={onFailureDiscord}
                        state={getDiscordSignature()}
                        isCrossOrigin={true}
                        className='blue-button'
                        responseType='token'
                        buttonText="Change my Discord username"
                      />
                    </s.Container>
                    <s.Container flex={1} jc={"left"} ai={"center"}>
                      <StyledButton
                        onClick={getDiscordUsername}
                      >
                        Validate Discord Username
                      </StyledButton>
                    </s.Container>
                  </ResponsiveWrapperRow>
                }
              </>
            }
            <s.SpacerLarge />
            <ResponsiveWrapperRow flex={1} style={{ padding: 24 }} ai={"center"}>
              {
                !data.user_tokens ?
                  <s.TextDescription>
                    You need a Cronos Ragdoll to use this section.
                  </s.TextDescription>
                  :
                  <></>
              }
            </ResponsiveWrapperRow>
          </s.Container> : <></>
      }
      <NotificationContainer />
    </s.Screen >
  );
}

export default App;
