// log
import store from "../store";

const fetchDataRequest = () => {
  return {
    type: "CHECK_DATA_REQUEST",
  };
};

const fetchNFTsRequest = () => {
  return {
    type: "CHECK_NFTS_REQUEST",
  };
};

const fetchDataSuccess = (payload) => {
  return {
    type: "CHECK_DATA_SUCCESS",
    payload: payload,
  };
};

const fetchNFTsSuccess = (payload) => {
  return {
    type: "CHECK_NFTS_SUCCESS",
    payload: payload,
  };
};

const fetchDataFailed = (payload) => {
  return {
    type: "CHECK_DATA_FAILED",
    payload: payload,
  };
};

export const fetchData = () => {
  return async (dispatch) => {
    dispatch(fetchDataRequest());
    try {
      let totalSupply = await store
        .getState()
        .blockchain.smartContract.methods.totalSupply()
        .call();

      let cost = await store
        .getState()
        .blockchain.smartContract.methods.cost()
        .call();

      let paused = await store
        .getState()
        .blockchain.smartContract.methods.paused()
        .call();


      let walletOfOwner = await store
        .getState()
        .blockchain.smartContract.methods.walletOfOwner(store.getState().blockchain.account)
        .call();

      dispatch(
        fetchDataSuccess({
          totalSupply,
          cost,
          paused,
          walletOfOwner
        })
      );
    } catch (err) {
      console.log(err);
      dispatch(fetchDataFailed("Could not load data from contract."));
    }
  };
};

export const fetchNFTs = () => {
  return async (dispatch) => {
    dispatch(fetchNFTsRequest());
    try {
      let walletOfOwner = await store
        .getState()
        .blockchain.smartContract.methods.walletOfOwner(store.getState().blockchain.account)
        .call();

      var user_tokens_unresolved = Array()
      if (walletOfOwner.length > 0) {
        console.log(walletOfOwner.length + " tokens :)")
        walletOfOwner.forEach(element => {
          let uri = store.getState().blockchain.smartContract.methods.tokenURI(element).call().then((data) => {
            let ipfs_split = data.split("ipfs://")
            var tokenURI = 'https://galleryapi.cronosragdolls.com/api/get_nft_metadata/0x21e68422011a330befdf45b0e45072c947316c95/' + element;
            return fetch(tokenURI).then((response) => response.json()).then((responseJson) => {
              let parsed_json = responseJson;
              parsed_json['image'] = 'https://galleryapi.cronosragdolls.com/api/get_nft_media/0x21e68422011a330befdf45b0e45072c947316c95/' + parsed_json['token_id'];
              return parsed_json;
            }).catch((reason) => {
              console.log(reason)
              return {
                "name": "Ups!",
                "description": "There was a problem loading the metadata. Try again later.",
                "image": "https://ixti.mypinata.cloud/ipfs/QmWMjvEakNivBUHanJw5BQjKRtjG5Pt1Y7A2Z1MWKx2dwx",
                "attributes": [
                  {
                    "trait_type": "Body",
                    "value": ""
                  },
                  {
                    "trait_type": "Hat",
                    "value": ""
                  },
                  {
                    "trait_type": "Monocle",
                    "value": ""
                  },
                  {
                    "trait_type": "Pipe",
                    "value": ""
                  },
                  {
                    "trait_type": "Smoke",
                    "value": ""
                  },
                  {
                    "trait_type": "Weapon",
                    "value": ""
                  },
                  {
                    "trait_type": "Color",
                    "value": ""
                  }
                ]
              }
            })
          });
          user_tokens_unresolved.push(uri)
        });
      } else {
        console.log("No tokens :(")
        console.log(walletOfOwner)
      }
      let user_tokens = await Promise.all(user_tokens_unresolved)
      console.log((user_tokens))
      dispatch(
        fetchNFTsSuccess({
          walletOfOwner,
          user_tokens
        })
      );
    } catch (err) {
      console.log(err);
      dispatch(fetchDataFailed("Could not load the NFTs."));
    }
  };
};
